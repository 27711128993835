<template>
  <div class="hello">
    <h2>Total Waves on DAO treasury: {{ waves_currently_on_daya }}</h2>
    <h3>Total ongoing vesting: {{ total_vesting_used }}/2</h3>
    <select class="mb-5" v-model="current_display_addr" @change="change_addr()">
      <option value="3PFyoZZiRDg92kd25VNoDbRiqtTpj7kCKL1" >Node</option>
      <option value="3PHvwN8hHP3Egoz8iZPJomnDQTtyJzUtWdY" >Power</option>
      <option value="3P4JutBKW6pADm91FxMfGL3EYxKJtWYsWfX" >Sign Art</option>
      <option value="3PCeRhqWbEVYBobvct51rrK9MVk95tw13G4" >BAI</option>
      <option value="3PFJgYn9tJVM2nNp5zDnb8qs4CUHTaNbScs" >Puzzle</option>
      <option value="3PLVKo7PHaULuYVso5GYPL8qKXJkKEnHapC" >East</option>
      <option value="3P2thwtbeBQb5cAodoBt2Esn8TsDfJx3hqN" >Marketing DAO</option>
      <option value="3PAnedq9FteKW1rxSaohM6Xk4D5zBSHkkW4" >Wavesduck</option>
      <option value="3PEA2Mr8CgWXdaosBUK3TqxpWDC4MSofsYn" >WX DAO</option>
    </select>

    <div id="detail">
      <a :href="'https://w8.io/'+current_display_addr" target="_blank">{{ current_display_addr }}</a><br/>
      Vesting started at height: <strong>{{ start_height }}</strong><br/>
      For <strong>{{ wavelets_per_block / Math.pow(10,8) }}</strong> Waves per blocks<br/>
      Already claimed <strong>{{ check_if_active() }} </strong> Waves <span class="usd">({{ this.total_claimed_in_usdt }} USDT)</span><br/>
      Available since last claim: <strong>{{ !current_display_addr_finished ? can_claim_now / Math.pow(10,8) : 'Finished' }} </strong> Waves<br/>
      Until: <strong>{{  check_max_claim() }}</strong>
    </div>
    <ul id="history">
      <li v-for="(item, index) in history" :key="index">
        <div class="me-2">{{ item.date }}</div>
        <div class="me-2">{{  item.method }}</div>
        <div>{{ item.height}} - received: {{ item.waves_received }} Waves <span class="usd">({{ item.amount_in_usdt }} USDT at {{ item.usdt_price_at_height }} USDT)</span></div>
      </li>
    </ul>
  </div>
</template>

<script>
console.log("process.env.VUE_APP_node_url", process.env.VUE_APP_node_url)
import axios from "axios"
import dayjs from 'dayjs'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: function() {
    return {
      oracle_dapp: "3PKkojKdd6BBzTf1RXbQVfUDraNFXXHKzQF",
      dao_funding_dapp: "3PEwRcYNAUtoFvKpBhKoiwajnZfdoDR6h4h",
      start_height_suffix: "_start_height",
      wavelets_per_block_suffix: "_wavelets_per_block",
      wavelets_claimed_suffix: "wavelets_claimed",

      wavelets_max_claimable_suffix: "_max_claimable",
      wavelets_max_claimable_is_usd_suffix: "_max_claimable_is_usd",
      wavelets_usdtlets_claimed_suffix: "_usdtlets_claimed",

      wavelets_max_claimable: 0,
      wavelets_max_claimable_is_usd: false,
      wavelets_usdtlets_claimed: 0,

      node_url: process.env.VUE_APP_node_url,
      current_display_addr: "3PFyoZZiRDg92kd25VNoDbRiqtTpj7kCKL1",
      current_display_addr_finished: false,
      current_height: 0,
      start_height: 0,
      wavelets_per_block: 0,
      wavelets_claimed: 0,
      can_claim_now: 0,
      max_to_claim: {
        addr_3P4JutBKW6pADm91FxMfGL3EYxKJtWYsWfX: "37 500 USD",
        addr_3PFyoZZiRDg92kd25VNoDbRiqtTpj7kCKL1: "200 000 Waves",
        addr_3PHvwN8hHP3Egoz8iZPJomnDQTtyJzUtWdY: "Unlimited",
        addr_3PCeRhqWbEVYBobvct51rrK9MVk95tw13G4: "61 200 USD",
        addr_3PFJgYn9tJVM2nNp5zDnb8qs4CUHTaNbScs: "December 2023",
        addr_3PLVKo7PHaULuYVso5GYPL8qKXJkKEnHapC: "January 2023",
        addr_3PAnedq9FteKW1rxSaohM6Xk4D5zBSHkkW4: "26 784 Waves",
        addr_3PEA2Mr8CgWXdaosBUK3TqxpWDC4MSofsYn: "100 000 Waves"
      },
      total_vesting_used: 0,
      waves_currently_on_daya: 0,
      history: [],
      last_height_with_usdt_price: 0,
      total_claimed_in_usdt:0,
      is_loading_history: false,
      is_loading_history_break: false,
      data_checked: false,
      claim_last: false,
      Claim_last: false
    }
  },
  created(){
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    if(params.get("addr")){
      this.current_display_addr = params.get("addr")
    }
    console.log("this.current_display_addr in created", this.current_display_addr)
  },
  mounted(){
    this.get_data()
    this.get_total_vesting_used()
  },
  methods: {
    check_max_claim: function(){
      if(this.wavelets_max_claimable!=0){
        let ticker = this.wavelets_max_claimable_is_usd ? 'USDT' : 'Waves'
        return this.wavelets_max_claimable + " " + ticker
      }else{
        return this.max_to_claim["addr_"+this.current_display_addr]
      }
    },
    check_if_active: function(){
      if(!this.data_checked){return "..."}
      if(this.wavelets_claimed < 0 || this.wavelets_per_block == 0){
        this.current_display_addr_finished = true
        return this.wavelets_claimed
      }else{
        return this.wavelets_claimed / Math.pow(10,8)
      }
    },
    get_waves_usdt_by_height: async function(height){
      let index = 0
      let loop = 10
      let price
      while(loop){
        let new_height = height + index 
        let key = encodeURIComponent("%s%s%d__priceByHeight__WAVES-USDT__"+new_height)
        console.log(key)
        try{
          console.log(key)
          price = await axios(this.node_url+"/addresses/data/"+this.oracle_dapp+"/"+key)
          console.log("test here",price.data.value)
          if(price.data.value){
            loop = false
            
          }else{
            console.log("price not found")
            
            index++
          }
        }catch(err){
          console.log(err)
          index++
        }
        index++
        if(price){
          return price.data.value / Math.pow(10,6)
        }
      }
    },
    get_claim_tx_history: async function(func, after = null){
      if(!this.is_loading_history){
        this.is_loading_history = true
        let index_loop =0
        //this.total_claimed_in_usdt = after ? this.total_claimed_in_usdt : 0
        const _this = this
        const useAfter = after ? "&after="+after : ""
        const history_claim = await axios(`https://api.wavesplatform.com/v0/transactions/invoke-script?sender=${this.current_display_addr}&dapp=3PEwRcYNAUtoFvKpBhKoiwajnZfdoDR6h4h&function=${func}&sort=desc&limit=100${useAfter}`)
        console.log("history_claim", history_claim)
        for(const tx of history_claim.data.data){
          if(!this.is_loading_history_break){
            console.log(index_loop)
            
            const tx_info = await axios(this.node_url+"/transactions/info/"+tx.data.id)
            console.log(tx_info.data.stateChanges.transfers[0].amount)
            const usdt_price_at_height = await this.get_waves_usdt_by_height(tx.data.height)
            
            console.log("usdt_price_at_height", usdt_price_at_height)
            const amount_in_usdt = Math.round(usdt_price_at_height * (tx_info.data.stateChanges.transfers[0].amount / Math.pow(10,8))*100)/100
            this.total_claimed_in_usdt =  Math.round((this.total_claimed_in_usdt + amount_in_usdt)*100)/100
/*             const temp_html = `
              <li>
                <div>${dayjs(tx.data.timestamp).format("YYYY-MM-DD HH:mm:ss")}</div>
                <div>claim()</div>
                <div>height: ${tx.data.height} - received: ${tx_info.data.stateChanges.transfers[0].amount / Math.pow(10,8)} Waves <span class="usd">(${amount_in_usdt} USDT at ${usdt_price_at_height} USDT)</span></div>
              </li>
            ` */
            _this.history.push({
              date: dayjs(tx.data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
              method: tx.data.call.function+"()",
              height: tx.data.height,
              waves_received: tx_info.data.stateChanges.transfers[0].amount / Math.pow(10,8),
              amount_in_usdt: amount_in_usdt,
              usdt_price_at_height: usdt_price_at_height
            })
            //await new Promise((resolve) => {setTimeout(() => {resolve(true)}, 2000)});
            index_loop++
          }else{
            this.is_loading_history_break=false
            break;
          }
        }
        this.is_loading_history=false
        console.log("history_claim.data.isLastPage", history_claim.data.isLastPage)
        if(func == "Claim"){
          if(!history_claim.data.isLastPage){
            await this.get_claim_tx_history(func, history_claim.data.lastCursor)
          }else{
            await this.get_claim_tx_history("claim", history_claim.data.lastCursor)
          }
        }
        if(func == "claim"){
          if(!history_claim.data.isLastPage){
            await this.get_claim_tx_history(func, history_claim.data.lastCursor)
          }
        }
        return
      }
    },
    get_dao_balance: async function(){
      let waves_on_dao = await axios(this.node_url+"/addresses/balance/"+this.dao_funding_dapp)
      console.log(waves_on_dao.data.balance)
      this.waves_currently_on_daya = waves_on_dao.data.balance / Math.pow(10,8)
    },
    get_total_vesting_used: async function(){
      let data_wavelets_per_block = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches=(.*)"+this.wavelets_per_block_suffix)
      console.log(data_wavelets_per_block.data)
      let _this = this
      data_wavelets_per_block.data.forEach(function(d){
        _this.total_vesting_used+= d.value /Math.pow(10,8)
      })
      _this.total_vesting_used = _this.total_vesting_used.toFixed(2)
    },
    get_data: async function(){
      this.get_dao_balance()
      console.log("this.current_display_addr in get_data", this.current_display_addr)
      let addr = this.current_display_addr
      
      this.history = []
      
      let data_start_height = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches="+addr+this.start_height_suffix)
      let data_wavelets_per_block = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches="+addr+this.wavelets_per_block_suffix)
      let data_wavelets_claimed = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches="+addr+this.wavelets_claimed_suffix)
      let data_wavelets_max_claimable = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches="+addr+this.wavelets_max_claimable_suffix)
      let data_wavelets_max_claimable_is_usd = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches="+addr+this.wavelets_max_claimable_is_usd_suffix)
      let data_wavelets_usdtlets_claimed = await axios(this.node_url+"/addresses/data/"+this.dao_funding_dapp+"/?matches="+addr+this.wavelets_usdtlets_claimed_suffix)
      let data_current_height = await axios(this.node_url+"/blocks/height")

      if(!data_start_height.data.length){
        this.detail = `Not started yet.`
        return
      }
      this.current_height = data_current_height.data.height
      this.start_height = data_start_height.data[0].value
      this.wavelets_per_block = data_wavelets_per_block.data[0].value
      this.wavelets_claimed = data_wavelets_claimed.data[0].value

      this.wavelets_max_claimable_is_usd = data_wavelets_max_claimable_is_usd.data[0] ? data_wavelets_max_claimable_is_usd.data[0].value : 0
      console.log("el pepitoooooooooooooooo", data_wavelets_max_claimable_is_usd.data[0])
      if(data_wavelets_max_claimable.data[0]){
        if(this.wavelets_max_claimable_is_usd){
          this.wavelets_max_claimable = data_wavelets_max_claimable.data[0].value / Math.pow(10,6)
        }else{
          this.wavelets_max_claimable = data_wavelets_max_claimable.data[0].value / Math.pow(10,8)
        }
      }else{
        this.wavelets_max_claimable = 0
      }
      this.wavelets_usdtlets_claimed = data_wavelets_usdtlets_claimed.data[0] ? data_wavelets_usdtlets_claimed.data[0].value : 0

      console.log("current_height", this.current_height)
      console.log("start_height", this.start_height)
      console.log("wavelets_per_block", this.wavelets_per_block)
      console.log("wavelets_claimed", this.wavelets_claimed)

      this.can_claim_now = ((this.current_height - this.start_height) * this.wavelets_per_block) - this.wavelets_claimed
      console.log("can_claim_now", this.can_claim_now / Math.pow(10,8))
      this.data_checked = true
      await this.get_claim_tx_history("Claim")
      //this.get_claim_tx_history("claim")
    },
    change_addr: function(){
      console.log("addr: ", this.current_display_addr)
      window.history.pushState({page: "vesting"}, "Vesting tracker", window.location.origin + '?addr='+this.current_display_addr)
      window.location.reload()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.me-2{
  margin-right: 10px;
}
.usd{
  font-size: 14px;
  color: rgb(161, 161, 161);
}
#detail{
  line-height: 22px;
}
#history{
  display: inline-flex;
  flex-direction: column;
  margin: auto;
  margin-top: 25px;
}
#history li{
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
h2{
  margin-bottom: 0;
}
h3{
  margin-bottom: 25px;
  margin-top: 20px!important;
  display: block;
}
select{
  margin-top: 25px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 18px;
}
.mb-5{
  margin-bottom: 25px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 0 10px 0!important;
}
a {
  color: #42b983;
}
</style>
